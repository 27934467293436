import logo from "./logo.svg";
import "./App.scss";
import "./styles/tailwind.css";

import React, { useState } from "react";
import Navigation from "./components/Navigation";
import Banner from "./components/Banner";
import BannerItem from "./components/BannerItem";
import Checkout from "./components/Checkout";
import Calender from "./components/Calender";
import Carousel from "./components/elements/Carousel";
import Footer from "./components/Footer";
import { CheckCircleIcon } from "@heroicons/react/solid";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";

const plyrProps = {
  source: undefined, // https://github.com/sampotts/plyr#the-source-setter
  options: undefined, // https://github.com/sampotts/plyr#options
  // Direct props for inner video tag (mdn.io/video)
};
const benefits = [
  "Competitive salaries",
  "Flexible work hours",
  "30 days of paid vacation",
  "Annual team retreats",
  "Benefits for you and your family",
  "A great work environment",
];

const Items = [
  {
    title: "Testimonies",
    img: "./images/healing_streams.jpg",
    content:
      "Share your testimonies with us. Kindly send us an email @ info.admin@healingschool.org.uk or call us on +44 3331880710",
    btnHide: true,
  },
  {
    title: "Healing Everywhere Campaign",
    img: "./images/janmagdown21.jpg",
    content:
      "Join us in taking the divine healing of God to billions around the world by not only sponsoring, but distributing the Healing to the Nations magazines everywhere",
    btnHide: false,
    btnText: "Download",
    btnHref: "https://enterthehealingschool.org/magazine.php?c=2&p=51899",
  },
  {
    title: "Healing Streams TV",
    img: "./images/pastor_chris.jpg",
    content:
      "Relish awesome testimonies and praise reports from different parts of the world by logging into Healing Streams TV (healingstreams.tv) which streams 24 hours every day of the week. You can also participate by downloading the Healing School Mobile App on Playstore and Appstore.",
    btnHide: false,
    btnText: "Explore",
    btnHref: "https://healingstreams.tv/live.php",
  },
  {
    title: "Download The Healing School Mobile App",
    img: "./images/healingsch.jpg",
    content:
      "Watch inspiring videos, listen to faith stirring audio podcasts, register for upcoming sessions, take your daily confessions and more. The all-new Healing School Mobile app gives you access to a multi-lingual experience with translations in French, German, Russian, Spanish and so much more",
    btnHide: false,
    btnText: "Download",
    btnHref: "https://enterthehealingschool.org/mobileapp/",
  },
];

const videoSrc = {
  type: "video",
  sources: [
    {
      src: "/gmfs.mp4",
      type: "video/mp4",
      size: 720,
    },
  ],
};

export default function App() {
  return (
    <div className="bg-white">
      {/* Header */}
      <Navigation />
      <div class="max-w-7xl mx-auto">
        <div className="px-3">
          <Carousel />
        </div>
        <Checkout />
        {/* <div className="bg-gray-900 py-24 rounded-md">
          <div className="relative isolate">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                <div
                  className="h-96 object-cover w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                  src="/gmfs.mp4"
                  alt=""
                >
                  <Plyr
                    options={{
                      controls: [
                        "play-large",
                        "play",
                        "progress",
                        "current-time",
                        "mute",
                        "volume",
                        "captions",
                        "settings",
                        // "pip",
                        "fullscreen",
                      ],
                      captions: {
                        active: true,
                        language: "auto",
                        update: true,
                      },
                      previewThumbnails: { enabled: false, src: "" },
                    }}
                    source={videoSrc}
                  />
                </div>
                <div className="w-full flex-auto">
                  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Global Miracle Faith Seminar
                  </h2>
                  <p className="mt-6 text-lg leading-8 text-gray-300">
                    Saturday 27 April 2024 (12 p.m. GMT+1) - The Global Miracle
                    Faith Seminar is one platform that provides the opportunity
                    to celebrate the streams of miracles wrought by the power of
                    God through various Healing School programs and platforms.
                    It strengthens faith in countless hearts, and upgrades
                    knowledge of God’s Word concerning healing and divine
                    health.
                  </p>
                  {/* <ul
                  role="list"
                  className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                >
                  {benefits.map((benefit) => (
                    <li key={benefit} className="flex gap-x-3">
                      <CheckCircleIcon
                        className="h-7 w-5 flex-none"
                        aria-hidden="true"
                      />
                      {benefit}
                    </li>
                  ))}
                </ul>
                  <div className="mt-10 flex">
                    <a
                      href="https://healingstreams.tv/gmfs/online_reg.php"
                      className="text-sm font-semibold leading-6 bg-white p-2 rounded-md text-indigo-800"
                    >
                      Register here <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                style={{
                  clipPath:
                    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                }}
              />
            </div>
          </div>
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 px-4 md:px-6 lg:px-8 mx-auto mt-10">
          {Items.map((item) => (
            <BannerItem
              title={item.title}
              img={item.img}
              content={item.content}
              btnHide={item.btnHide}
              btnText={item.btnText}
              btnHref={item.btnHref}
            />
          ))}
        </div>
        {/* <div className='mt-8 px-8'><Calender /></div> */}
      </div>
      <Footer />
    </div>
  );
}
