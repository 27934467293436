import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/outline';

export default function Notification({
    heading, content, show, setShow,
}) {
    return (
      <div className="pointer-events-none fixed z-[999] inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-12">
                 <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-green-600 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
        <p className="text-sm leading-6 text-white">
            <strong className="font-semibold">Healing School Intl Office</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Transaction successful
        </p>
        <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
      </div>
      </Transition>
    </div>

      );
}
