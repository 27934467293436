import './../../App.scss';
import './../../styles/tailwind.css';

import React from 'react'

export default function Currency({currency, setValue, currencies}) {
    const onOptionChange = e => {
        setValue(JSON.parse(e.target.value)); 
      }

  return (
      <><h2 className="text-lg font-medium mb-4 text-gray-900">Currency</h2><div className="rounded-lg px-4 py-4 border border-gray-200 bg-white shadow-sm">
          <fieldset>
              <legend className="sr-only">Currency</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-8">
                  {currencies.map((currency) => (
                      <div key={currency.id} className="flex items-center">
                          <input
                              id={currency.id}
                              name="notification-method"
                              type="radio"
                              value={JSON.stringify(currency)}
                              defaultChecked={currency.id === 'gbp'}
                              onChange={onOptionChange}
                              className="h-4 w-4 border-gray-300 text-cyan-600 focus:ring-cyan-600" />
                          <label htmlFor={currency.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {currency.title}
                          </label>
                      </div>
                  ))}
              </div>
          </fieldset>
      </div></>
)
}