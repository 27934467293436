import './../App.scss';
import './../styles/tailwind.css';

import React from 'react'

export default function BannerItem({title, img, content, btnText, btnHref, btnHide}) {

  return (
      <div className="flex flex-col pb-8 text-center overflow-clip rounded-md bg-neutral-200">
      <div
            className="text-center h-48 bg-cover bg-center no-repeat mb-10 text-white flex items-center justify-center"
            style={{
              backgroundImage: `linear-gradient(90deg, rgba(0,136,185,.4) 100%, rgba(0,136,185,0) 100%), url(${img})`,
            }}>
                            <h1 className="hs-font text-3xl drop-shadow-xl">{title}</h1>
              </div>
              <div class="flex flex-col px-3">
              <p className="mb-6 text-lg">{content}</p>
              {!btnHide && (
              <a href={btnHref} target="_blank" class="hs-font mx-auto text-xl bg-white text-black rounded-none w-32 py-2 drop-shadow-lg">{btnText}</a>
              )}
              </div>
                    </div>

  )
}