import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Amount({currency, amount, setAmount}) {

  return (
      <div className="relative my-4 w-full">
        <input
          type="number"
          name="name"
          id="name"
          pattern="[0-9]{1,5}"
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
          className="peer block w-full border-0 py-1.5 text-gray-900 sm:text-sm sm:leading-6"
          placeholder="Amount"
        />
        <div
          className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-cyan-600"
          aria-hidden="true"
        />
      </div>
  )
}
