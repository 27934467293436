import { useState, useEffect } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

const style = {"layout":"vertical"};

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, amount, basket, setBasket, setShow, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

const createOrder = () => {
    const x = basket.map((item) => (
        {name: item.title, quantity: 1, unit_amount:{currency_code: currency, value:item.price}}
    ));

        return {
        purchase_units: [{
            description: "Healing School Online Transaction",
            amount: {
              value: amount,
              currency_code: currency,
              breakdown: {
                item_total: {
                  currency_code: currency,
                  value: amount,
                }
              }
            },
            items: x,
        }],
    };
}

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);


    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create(createOrder())
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        // Your code here after capture the order
                        setBasket([]);
                        setShow(true);
                        setTimeout(() => {
                            setShow(false);
                        }, 10000);
                    });
                }}
            />
        </>
    );
}

export default function Paypal(value) {
	return (
		<div style={{ maxWidth: "750px"}}>
            <PayPalScriptProvider
                options={{
                    "client-id": process.env.REACT_APP_CLIENT_ID,
                    components: "buttons",
                    currency: value.importCurrency,
                }}
            >
				<ButtonWrapper
                    currency={value.importCurrency}
                    amount={value.importAmount}
                    showSpinner={false}
                    basket= {value.basket}
                    setBasket={value.setBasket}
                    setShow={value.setShow}
                />
			</PayPalScriptProvider>
		</div>
	);
}