import logo from '../Assets/images/logo.png'


export default function Navigation() {

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-center p-2 lg:px-8" aria-label="Global">
        <a href="/" className="-m-1.5 p-1.5">
          <span className="sr-only">Healing School</span>
          <img className="h-12 w-auto" src={logo} alt="" />
        </a>
      </nav>
    </header>
  )
}
