import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';

const content = [
    {
      title: 'Healing Streams Live TV',
      description: `The Healing Streams TV is a healing and miracle channel that creates an atmosphere for faith and the miraculous in and around our viewers in such a way that they take a hold of God’s plan for divine health and healing and use it to cause changes in their lives.`,
      image: './images/main_bg.jpg',
    }
  ];

export default function Carousel () {
  return (
    <div className=" items-center">
    <Swiper
      loop
      spaceBetween={10}
      slidesPerView="auto"
      centeredSlides
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay]}
      className="featured-slider-item w-full"
    >
        {content.map((slide) => (
                  <SwiperSlide>
                  <div
                        className="md:hidden h-full py-16 md:h-80 lg:h-128 flex flex-col justify-center text-left rounded-lg xl:rounded-md bg-cover bg-center m-1 text-white "
                        style={{
                          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,.7) 100%, rgba(0,0,0,0) 100%), url(${slide.image})`,
                        }}>
                            <div class="mx-8 md:ml-12 max-w-xl">
                                            <h1 className="text-left font-extrabold mb-3.5 text-3xl">
                  {slide.title}
                </h1>
                <p className='text-md'>{slide.description}</p>
                <a href="https://healingstreams.tv/live.php"
                    className="inline-block font-semibold text-white items-center mt-4 px-6 py-3 border-solid border-white border bg-transparent hover:bg-cyan-500/[.6] transition text-sm leading-tight rounded-lg focus:outline-none focus:ring-0 transition duration-300 ease-in-out"
                  >
                    Watch Live
                  </a>
                </div>
                        </div>


                        <div
                        className="hidden md:flex h-full py-8 md:h-80 lg:h-128 flex-col justify-center text-left rounded-lg xl:rounded-md bg-cover bg-center m-1 text-white "
                        style={{
                          backgroundImage: `linear-gradient(90deg, rgba(0,0,0,.9) 30%, rgba(0,0,0,0) 80%), url(${slide.image})`,
                        }}>
                            <div class="mx-8 md:ml-12 max-w-xl">
                                            <h1 className="text-left font-extrabold mb-3.5 text-3xl">
                  {slide.title}
                </h1>
                <p className='text-md'>{slide.description}</p>
                <a href="https://healingstreams.tv/live.php"
                    className="inline-block font-semibold text-white items-center mt-4 px-6 py-3 border-solid border-white border bg-transparent hover:bg-cyan-500/[.6] transition text-sm leading-tight rounded-lg focus:outline-none focus:ring-0 transition duration-300 ease-in-out"
                  >
                    Watch Live
                  </a>
                </div>
                        </div>
                  </SwiperSlide>
        ))}
    </Swiper>
    </div>
    
  );
};